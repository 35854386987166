<template>
  <div id="Login">
    <!-- <TheHeader></TheHeader>
    <SiteNav v-if="user"></SiteNav> -->
    <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-lines bg-cover bg-center">
    <div class="max-w-md w-full space-y-8">
      <img class="mx-auto h-28 w-auto" src="@/assets/logo_border.png" alt="Workflow" />
      <div class="bg-gray-800 rounded-lg mt-6 pb-3 p-3">
        <div class="text-shadow">
          
          <h2 class="mt-4 text-center text-3xl font-extrabold text-white">
            Anmeldung
          </h2>
          <p class="mt-2 text-center text-sm text-gray-300">
            Oder
            {{ ' ' }}
            <a href="/registrieren" class="font-medium text-blue-600 hover:text-blue-500">
              kostenfrei im Club registrieren.
            </a>
          </p>
        </div>



        <div class="p-3 my-3 text-black text-center bg-gray-200 ring-2 ring-red-700 rounded-lg" v-if="showAlertWrongData">
          <div class="">
            <p class="headline text-lg leading-6 font-medium text-red-700">Falsche Anmeldedaten</p>
            <p class="text">
              Deine E-Mail Adresse oder Dein Passwort ist falsch. Versuche es
              bitte erneut.<br />Du kannst Dein
              <a href="/passwort-vergessen" class="link">Passwort zurücksetzen</a>, falls Du es
              vergessen hast.
            </p>
            <!-- <button type="button" class="button-filled text-white bg-blue-900 hover:bg-blue-800 rounded-lg p-3 mt-3" @click="requestEmailConfirmationen" v-if="requestEmailConfirmationenShowButton">
              E-Mail erneut zusenden?
            </button> -->
          </div>
        </div>



        <!-- <div class="alert alert-default space" v-if="showAlertWrongData">
            <p class="alert-heading">
              <b>Falsche Anmeldedaten</b>
            </p>
            <p>
              Deine E-Mail Adresse oder Dein Passwort ist falsch. Versuche es
              bitte erneut.<br />Du kannst Dein
              <a href="" class="link">Passwort zurücksetzen</a>, falls Du es
              vergessen hast.
            </p>
          </div> -->
        <form class="mt-8 space-y-6" action="#" method="POST" v-on:submit.prevent>
          <input type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px text-center">
            <div>
              <h4 class="mt-6  font-extrabold text-white text-shadow">
                E-Mail Adresse
              </h4>
              <label for="email-address" class="sr-only">E-Mail Adresse</label>
              <input @input="checkInputs" id="email-address" name="email" type="email" autocomplete="email" required v-model.trim="email" :disabled="isLoading" class="text-center appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring focus:brand-color-dark  focus:z-10 sm:text-sm" placeholder="E-Mail Adresse" />
            </div>
            <div>
              <h4 class="mt-6  font-extrabold text-white text-shadow">
                Passwort
              </h4>
              <label for="password" class="sr-only">Password</label>
              <input @input="checkInputs" id="password" name="password" type="password" autocomplete="current-password" required="" v-model.trim="password" :disabled="isLoading" class="text-center appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring focus:brand-color-dark focus:z-10 sm:text-sm" placeholder="Passwort" />
            </div>
          </div>

          <div class="flex items-center justify-center">


            <!-- <div class="text-sm"> -->
              <a href="/passwort-vergessen" class="text-center text-sm font-medium text-blue-600 hover:text-blue-500 text-shadow">
                Passwort vergessen?
              </a>
            <!-- </div> -->
          </div>

          <div>
            <button :disabled="isLoading || stepCompleteDisabled" @click="login" type="submit" class="button-filled group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white hover:bg-blue-400">
              <font-awesome-icon icon="sign-in-alt" class="icon my-auto mr-1" /> Anmelden
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>


    <!-- <div class="container">
      <div class="text-center">
        <h1>WELCOME BACK</h1>
        <div class="badge badge-brand">STADTBAHNFAHRER:IN</div>
      </div>
      <div class="space"></div>

      <div class="content-card-container card-one-center">
        <div class="lol" v-if="user"> -->
          <!-- <div class="alert alert-default space" v-if="user.blocked">
            <p class="alert-heading">
              <b>Konto gesperrt</b>
            </p>
            <p>Dein Konto ist gesperrt. Wende Dich bitte an unseren Support.</p>
          </div>

          <div class="alert alert-default " v-if="!user.confirmed">
            <p class="alert-heading">
              <b>E-Mail Adresse bestätigen</b>
            </p>
            <p>Bestätige bitte Deine E-Mail Adresse. Wir haben Dir dazu eine E-Mail zugesandt.<br><a class="link">E-Mail erneut zusenden?</a></p>
          </div>

          <div class="alert alert-default " v-if="!user.verified">
            <p class="alert-heading">
              <b>Konto verifizieren</b>
            </p>
            <p>Verifiziere bitte Dein Konto, um alle Funktionen nutzen zu können.</p>
          </div> -->
<!-- 
          <div class="notification">
            <font-awesome-icon icon="hard-hat" class="icon" size="4x" />
            <p class="headline">BETA</p>
            <hr class="line" />
            <p class="text">
              Der StadtbahnfahrerClub befindet sich in einer frühen BETA-Phase. Der Funktionsumfang und Dein Nutzerelebnis kann sich jederzeit ändern. 
            </p>
          </div>

          <p class="text-center space">
            Angemeldet als<br />{{ user.first_name }} {{ user.last_name }}
          </p>

          <p class="text-center space">
            E-Mail: {{ user.email }}<br />ID: {{ user.id }}<br />Company:
            {{ user.company.name }}
          </p>

          <a href="/dashboard" class="button-filled btn-fullsize text-center">
            Zum Dashboard
          </a>

          <button @click="logout" class="button-outline btn-fullsize">
            Abmelden
          </button>
        </div>

        <div class="lol" v-else>
          <h1 class="brand-color-dark text-center">Anmeldung</h1>
          <p class="text-center">Melde Dich mit Deinen Anmeldedaten an.</p>
          <div class="alert alert-default space" v-if="showAlertWrongData">
            <p class="alert-heading">
              <b>Falsche Anmeldedaten</b>
            </p>
            <p>
              Deine E-Mail Adresse oder Dein Passwort ist falsch. Versuche es
              bitte erneut.<br />Du kannst Dein
              <a href="" class="link">Passwort zurücksetzen</a>, falls Du es
              vergessen hast.
            </p>
          </div>

          <div class="alert alert-default space" v-if="showAlertBlocked">
            <p class="alert-heading">
              <b>Konto gesperrt</b>
            </p>
            <p>Dein Konto ist gesperrt. Wende Dich bitte an unseren Support.</p>
          </div>

          <div class="alert alert-default space" v-if="showAlertMaintenance">
            <p class="alert-heading">
              <b>Wartungsarbeiten</b>
            </p>
            <p>
              Um unseren Service weiterhin zuverlässig anbieten zu können,
              arbeiten wir zurzeit am Anmelde-System.<br />Schaue bitte später
              wieder vorbei. Vielen Dank für Dein Verständnis.
            </p>
          </div>
          <form>
          <label for="email" class="brand-color-dark"
            ><b>E-Mail Adresse</b></label
          >
          <input
            type="email"
            placeholder="hallo@stadtbahnfahrer.club"
            name="email"
            class=""
            required
            v-model.trim="email"
            :disabled="isLoading"
          />

          <label for="psw" class="brand-color-dark"><b>Passwort</b></label>
          <input
            type="password"
            placeholder="**********"
            name="password"
            class=""
            required
            v-model.trim="password"
            :disabled="isLoading"
          />

          <button
            :disabled="isLoading"
            @click="login"
            type="submit"
            class="button-filled btn-fullsize"
          >
            <span v-if="isLoading">Anmeldung lädt...</span
            ><span v-else>Anmelden</span>
          </button>
          </form>

          <hr class="space" />

          <p class="text-center bg-green text-cyan-500">
            Noch nicht im Club?<br /><a href="registrieren" class="link"
              >Kostenfrei registrieren</a
            >
          </p>

          <p class="text-center">
            <a href="passwort-vergessen" class="link">Passwort vergessen?</a>
          </p>
        </div>
      </div>
    </div>-->
  </div> 
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import { mapGetters } from "vuex";
import TheHeader from "@/components/TheHeader";

export default {
  name: "Login",
  metaInfo: {
    title: 'Anmelden'
  },
  components: {
    TheHeader,
    SiteNav,
  },
  data() {
    return {
      isLoading: false,
      email: "",
      password: "",
      showAlertWrongData: false,
      showAlertBlocked: false,
      showAlertMaintenance: false,
      stepCompleteDisabled: true
    };
  },
  methods: {
    checkInputs() {
      if(
      this.password.length > 0 &&
      this.email.length > 0) {
        this.stepCompleteDisabled = false
      } else {
        this.stepCompleteDisabled = true
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    login() {
      this.isLoading = true;

      this.showAlertWrongData = false;
      this.showAlertBlocked = false;
      this.showAlertMaintenance = false;

      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then((a) => {
          this.password = null;


          if (a == true) {
          } else {
            this.showAlertWrongData = true;
            this.stepCompleteDisabled = true
          }

          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped>
.badge {
  width: 220px;
  margin: auto;
}

.lol {
  max-width: 400px;
}
</style>